<template>
  <div class="header" ref="header">
    <div class="header__wrapper" ref="headerWrapper">
      <div
        class="header__img"
        :style="[
          { backgroundImage: setBackGroundPath(imgpath) },
          { backgroundPosition: imgposition },
        ]"
      ></div>
    </div>
  </div>
</template>

<script>
import getPathFunction from "@/utilities/composition/getPathFunction";
import { ref } from "@vue/runtime-core";
export default {
  props: { imgpath: String, imgposition: String, motion: String },
  setup() {
    const { setBackGroundPath } = getPathFunction();

    const headerWrapper = ref(null);
    const header = ref(null);

    return { setBackGroundPath, headerWrapper, header };
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  width: 100%;
  margin-bottom: 100px;

  @media screen and (min-width: $small) {
    margin-bottom: 150px;
  }
  @media screen and (min-width: $medium) {
    margin-bottom: 207px;
  }
  @media screen and (min-width: $large) {
    margin-bottom: 260px;
  }
  @media screen and (min-width: $xlarge) {
    margin-bottom: 260px;
  }
}
.header--aspectratio {
  position: relative;
  width: 100%;
  margin-bottom: 50px;

  @media screen and (min-width: $small) {
    margin-bottom: 75px;
  }
  @media screen and (min-width: $medium) {
    margin-bottom: 100px;
  }
  @media screen and (min-width: $large) {
    margin-bottom: 130px;
  }
  @media screen and (min-width: $xlarge) {
    margin-bottom: 130px;
  }
}

.header__wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.header__img {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
