<template>
  <div class="gallery">
    <div class="gallery__wrapper">
      <div class="gallery__item"><AppHl>projekte</AppHl></div>
      <div class="gallery__content">
        <div class="gallery__hero">
          <GalleryPic :imgpath="'assets/img/gallery/gallery_start/1.jpg'" />
        </div>
        <div class="gallery__leftblock">
          <div class="gallery__big">
            <GalleryPic :imgpath="'assets/img/gallery/gallery_start/2.jpg'" />
          </div>
          <div class="gallery__small">
            <GalleryPic :imgpath="'assets/img/gallery/gallery_start/3.jpg'" />
          </div>
        </div>
        <div class="gallery__rightblock">
          <div class="gallery__small">
            <GalleryPic :imgpath="'assets/img/gallery/gallery_start/4.jpg'" />
          </div>
          <div class="gallery__big">
            <GalleryPic :imgpath="'assets/img/gallery/gallery_start/5.jpg'" />
          </div>
        </div>
      </div>
      <div class="gallery__btn">
        <AppButton :routerlink="'projekte'">Weitere Projekte</AppButton>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "@/components/ui/buttons/AppButton.vue";
import AppHl from "@/components/ui/buttons/AppHl.vue";
import GalleryPic from "./GalleryPic";
export default {
  components: { GalleryPic, AppButton, AppHl },
  setup() {},
};
</script>

<style></style>
